import React, { useEffect, useState } from 'react';
import texts from '@components/Referral/en.json';
import { Button } from '@ui/atoms/design-system';
import { cn } from '@ui/helpers/utils';
import useWindowDimensions from '@hooks/useWindowDimensions';

const SignupStickyView = ({
    stickyBtnVisible,
    offset = 68,
    isSignUp,
    isNriVisitor,
}) => {
    const { height } = useWindowDimensions();

    const scrollToLoginSignup = () => {
        const element = document.getElementById('signUpForm');

        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const stickyHeader = document.querySelector('#sticky-header');
            const stickyHeaderHeight = stickyHeader?.clientHeight || 80;

            const minOffset = 20;
            const minNriHeight = 560;
            const minSignUpHeight = 618;
            let offset = 0;

            if (elementPosition > 0) {
                if (isNriVisitor) {
                    offset =
                        height > minNriHeight
                            ? height - minOffset - minNriHeight
                            : 0;
                } else {
                    offset =
                        height > minSignUpHeight ? height - minSignUpHeight : 0;
                }
                window.scrollTo({
                    top: elementPosition + window.scrollY - offset,
                    behavior: 'smooth',
                });
            } else {
                window.scrollTo({
                    top:
                        elementPosition +
                        window.scrollY -
                        stickyHeaderHeight / 2,
                    behavior: 'smooth',
                });
            }
        }
    };
    return (
        <div
            className={cn(
                `${stickyBtnVisible?.display} z-100 sticky bottom-0 bg-basicWhite px-4 py-4 w-screen text-center md:hidden transition-all`,
                {
                    'bg-basicWhite px-4 shadow-stats-shadow': isSignUp,
                    'shadow-home-page-banner-explore': !isSignUp
                }
            )}
        >
            <Button
                type="submit"
                buttonType="primary"
                widthClass="w-full"
                onClick={scrollToLoginSignup}
                buttonText={
                    isSignUp || isNriVisitor ? texts.SignUpInvest : texts.Login
                }
            />
        </div>
    );
};

export default SignupStickyView;
